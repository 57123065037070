import { regex } from 'ra-core';
import { BrandVettingMethod, LanguageLocale } from './types';

export const facingModeChoices = [
  { id: 'user', name: 'User' },
  { id: 'environment', name: 'Environment' },
];

export const emailProvidersChoices = [
  { id: 'CAST_EMAIL', name: 'Uplifted Email' },
  { id: 'KLAVIYO', name: 'Klaviyo' },
];

export const EmailTypeChoices = [
  { id: 'CAMPAIGN_REQUEST', name: 'Request' },
  { id: 'CAMPAIGN_REMINDER', name: 'Reminder' },
  { id: 'CAMPAIGN_INCENTIVE', name: 'Incentive' },
  { id: 'CAMPAIGN_RETAKE', name: 'Retake' },
  { id: 'CAMPAIGN_RETAKE_REMINDER', name: 'Retake Reminder' },
  { id: 'VIDEO_REJECTION', name: 'Video Rejection' },
  { id: 'CREATOR_WELCOME', name: 'Creator Welcome' },
  { id: 'CAMPAIGN_INCOMPLETION_REMINDER', name: 'Campaign Incompletion' },
  { id: 'CAMPAIGN_POST_SUBMISSION', name: 'Campaign Post Submission' },
  { id: 'CAMPAIGN_PARTICIPATION_REWARD', name: 'Participation Reward' },
];

export const SupportedLocalesChoices: LanguageLocale[] = [
  { id: 'en-US', shortId: 'en', name: 'English (US)', shortEngName: 'English' },
  {
    id: 'de-DE',
    shortId: 'de',
    name: 'Deutsch (Deutschland)',
    shortEngName: 'German',
  },
  {
    id: 'fr-FR',
    shortId: 'fr',
    name: 'Français (France)',
    shortEngName: 'French',
  },
  {
    id: 'es-ES',
    shortId: 'es',
    name: 'Español (Spain)',
    shortEngName: 'Spanish',
  },
  {
    id: 'pt-PT',
    shortId: 'pt',
    name: 'Português (Portugal)',
    shortEngName: 'Portuguese',
  },
  {
    id: 'pl-PL',
    shortId: 'pl',
    name: 'Polski (Poland)',
    shortEngName: 'Polish',
  },
  {
    id: 'he-IL',
    shortId: 'he',
    name: 'עברית (Israel)',
    shortEngName: 'Hebrew',
  },
  {
    id: 'sv-SE',
    shortId: 'sv',
    name: 'Swedish (Sweden)',
    shortEngName: 'Swedish',
  },
  {
    id: 'no-NO',
    shortId: 'no',
    name: 'Norwegian (Norway)',
    shortEngName: 'Norwegian',
  },
];

export const CurrencySignMap = {
  DE: '€',
  US: '$',
  UK: '£',
  ES: '€',
  FR: '€',
  PL: 'zł',
};

export const Tones = {
  FUNNY: 'FUNNY',
  INFORMATIVE: 'INFORMATIVE',
  HAPPY: 'HAPPY',
  EMOTIONAL: 'EMOTIONAL',
  INSPIRATIONAL: 'INSPIRATIONAL',
  SCARY: 'SCARY',
  RELAXING: 'RELAXING',
  EXCITING: 'EXCITING',
  SURPRISING: 'SURPRISING',
  FUSHOCKINGNNY: 'SHOCKING',
  UNUSUAL: 'UNUSUAL',
  CHILL: 'CHILL',
  POWERFUL: 'POWERFUL',
};

export const NoOfPeople = {
  SINGLE: 'Single',
  MULTIPLE: 'Multiple People',
};

export const EmotionalScale = {
  EMOTIONAL: 'Emotional',
  NO_PREFERENCE: 'No Preference',
  SCIENTIFIC: 'Scientific',
};

export const ValueScale = {
  VALUE_DIFF: 'Value Diff',
  NO_PREFERENCE: 'No Preference',
  PRICE: 'Price',
};

export const HEX_COLOR_REGEX =
  /^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$/i;

export const isHexColor = (msg?: string) =>
  regex(HEX_COLOR_REGEX, msg || 'Must be a hexadecimal color');

export const vettingMethod = [
  { id: BrandVettingMethod.MODERATOR, name: 'Moderator' },
  { id: BrandVettingMethod.BRAND, name: 'Brand' },
];

export const FILE_CHUNK_SIZE = 20 * 1024 * 1024; // 20MB

export const UploadingState = {
  UPLOADING: 'UPLOADING',
  FAILED_TO_UPLOAD: 'FAILED_TO_UPLOAD',
  UPLOADED_GENERATING_THUMBNAIL: 'UPLOADED_GENERATING_THUMBNAIL',
  UPLOADED_WITHOUT_THUMBNAIL: 'UPLOADED_WITHOUT_THUMBNAIL',
  UPLOADED: 'UPLOADED',
};

export const JobStatus = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  FAILED: 'FAILED',
  SUCCESS: 'SUCCESS',
  CANCELLED: 'CANCELLED',
};

export const BulkDownloadResponseType = {
  DIRECT: 'DIRECT',
  ASYNC: 'ASYNC',
  MAIL: 'MAIL',
  ERROR: 'ERROR',
};

export const adRequestCsvColumns = [
  'videoSession.id',
  'videoSession.name',
  'asset.id',
  'asset.name',
  'videoAd.id',
  'videoAd.name',
  'brand.id',
  'brand.name',
  'user.userId',
  'user.firstName',
  'user.lastName',
  'adObjective',
  'editingStyle',
  'numOfHooks',
  'platforms',
  'sizes',
  'tones',
  'createdAt',
  'guidelines',
];

export enum GuidelineType {
  CONSTRAINTS = 'CONSTRAINTS',
  SALE_RESISTANCE = 'SALE_RESISTANCE',
  VALUE_PROPS = 'VALUE_PROPS',
}

export enum IncentiveProvision {
  ALL_VETTED = 'Global',
  ONE_VETTED = 'Per step',
  PARTICIPATION = 'Participation',
}

export enum Library {
  BRAND = 'BRAND',
  UGC = 'UGC',
  AD = 'AD',
  CREATIVE = 'CREATIVE',
}
